'use strict'

###*
 # @ngdoc object
 # @name mundoSocket
 # @description

###
angular
  .module 'mundoSocket', [
    'ui.router'
  ]
  .run (
    $rootScope,
    MundoSocket,
    mundoConfiguration
  ) ->
    $rootScope
      .$on 'mundo:authentication:success', (event, data) ->
        if not MundoSocket.isInitialized()
          MundoSocket.setInitialized()
          MundoSocket.connect(mundoConfiguration.socketServer.url)
